/* Add this CSS to your stylesheet or global styles */
@keyframes popInOut {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    transform: scale(
      1.2
    ); /* Adjust the scale as needed for the "pop-in" effect */
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Apply the animation to an element using the class */
.pop-in-out-animation {
  animation: popInOut 1s; /* Adjust the animation duration as needed */
}

.no-select {
  user-select: none;
}
