.horizontal-scrollbar {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap; /* Prevent text wrapping */
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: darkgray lightgray; /* Firefox */
}

/* Optional: Style the scrollbar for Webkit browsers (Chrome, Safari) */
.horizontal-scrollbar::-webkit-scrollbar {
  width: 10px;
}

.horizontal-scrollbar::-webkit-scrollbar-track {
  background: lightgray;
}

.horizontal-scrollbar::-webkit-scrollbar-thumb {
  background-color: darkgray;
  border-radius: 5px;
}
